require('../../scss/frontend/index.scss');
require('../../scss/commons/network_map.scss');

require('slick-carousel/slick/slick.min');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

$(document).ready(function () {
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        focusOnSelect: true
    });

    var $carousel = $('.carousel-header').flickity();
    $carousel.on('select.flickity', function (event, index) {
        $('.slide').each(function () {
            let video = $(this).find('video');
            if (video && video[0]) {
                if ($(this).hasClass("is-selected")) {
                    video[0].currentTime = 0;
                    video[0].play();
                } else {
                    video[0].currentTime = 0;
                    video[0].pause();
                }
            }
        });
    });
    $('#slider').on('select.flickity', function () {
        $('#slider').flickity('playPlayer')
    });

    let nSlide = 1;
    $('#slider-logo .slide').each(function () {
        let logo = $(this).data('logo');
        let year = $(this).data('year');

        $('#slider-logo .dot:nth-child(' + nSlide + ')').html('<div class="dcs-logo"><img src="' + logo + '" alt="logo ' + year + '"></div><div class="year">' + year + '</div>');
        nSlide++;
    });

    setInterval(function () {
        const number = parseInt($('#realtime-update').html());
        $('#realtime-update').html(number + 1);
    }, 3500);

    if (false) {
        const backgroundElement = document.querySelector('#dcs-history-wrapper');
        const wrapperElement = document.querySelector('#dcs-timeline-wrapper');
        let containerElement = document.querySelector('#dcs-timeline.horizontal');
        const floorElement = document.querySelector('#floor');
        const footerElement = document.querySelector('#footer');
        floorElement.style.position = 'fixed';
        let footerHeightInterval = setInterval(function () {
            if ($('.polo-carousel-item').length > 0) {
                clearInterval(footerHeightInterval);
                footerElement.style.marginTop = (floorElement.getBoundingClientRect().height) + 'px';
            }
        }, 100);


        if (containerElement) {
            console.log('h-scroll');
            const scrollableElement = document.querySelector('#dcs-timeline.horizontal > div');

            const containerWidth = containerElement.scrollWidth;
            const containerMargin = containerElement.offsetLeft;
            const heightBase = backgroundElement.getBoundingClientRect().height;
            backgroundElement.style.maxHeight = (heightBase - 240) + 'px';
            backgroundElement.style.overflowY = 'visible';
            wrapperElement.style.height = (containerWidth - window.innerWidth + containerElement.getBoundingClientRect().height + containerMargin) + 'px';
            const minScroll = 0
            const maxScroll = containerWidth - window.innerWidth + 2 * containerMargin;

            console.log(maxScroll, heightBase, wrapperElement.style.height, backgroundElement.style.maxHeight);

            window.onscroll = (e) => {
                const position = 120 - wrapperElement.getBoundingClientRect().y;
                const containerSticky = position < minScroll ? 'top' : (position < maxScroll ? 'fixed' : 'bottom');
                const scrollablePosition = -(position < minScroll ? minScroll : (position < maxScroll ? position : maxScroll));

                switch (containerSticky) {
                    case 'top':
                        containerElement.style.position = 'relative';
                        containerElement.style.top = 'unset';
                        backgroundElement.style.backgroundPositionY = 'top';
                        backgroundElement.style.backgroundAttachment = 'unset';
                        break;
                    case 'fixed':
                        let offset = 200;
                        if (window.innerWidth <= 767) {
                            offset = 240;
                        }
                        containerElement.style.position = 'fixed';
                        containerElement.style.top = '120px';
                        if (window.innerWidth > 424) {
                            backgroundElement.style.maxHeight = (heightBase - offset - 40 + position) + 'px';
                        } else {
                            backgroundElement.style.maxHeight = (heightBase + position) + 'px';
                        }
                        backgroundElement.style.backgroundPositionY = (containerElement.getBoundingClientRect().top + containerElement.getBoundingClientRect().height - heightBase + offset) + 'px';
                        backgroundElement.style.backgroundAttachment = 'fixed';
                        break;
                    case 'bottom':
                        containerElement.style.position = 'relative';
                        containerElement.style.top = `${maxScroll}px`;
                        backgroundElement.style.maxHeight = (heightBase - 240 + maxScroll) + 'px';
                        backgroundElement.style.backgroundPositionY = `${maxScroll}px`;
                        backgroundElement.style.backgroundAttachment = 'unset';
                        break;
                }
                floorElement.style.top = (containerElement.getBoundingClientRect().top + containerElement.getBoundingClientRect().height) + 'px';
                scrollableElement.style.transform = `translateX(${scrollablePosition}px)`;
            }
        }
    }
});



